import React, { useState } from 'react'
import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, MenuItem, Select, Typography } from '@mui/material'
import * as Yup from 'yup';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { FormProvider, RHFTextField } from '../../components/hook-form';
import { createNewPlink } from 'store/actions';
import { toast } from 'react-toastify';
import moment from 'moment';

const style = {
    width: "auto",
    bgcolor: 'background.paper',
    border: 'none',
    outline: 'none',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
};

function NewLinkModalContent({ dispatch, handleClose }) {
    const [amount, setAmount] = useState(0.02);
    const [currency, setCurrency] = useState('solana');
    const [noExpiry, setNoExpiry] = useState(true);

    const handleAmountChange = (e) => {
        let amount = parseFloat(e.target.value)
        setAmount(amount);
    }

    const handleCurrencyChange = (e) => {
        let currency = e.target.value
        setCurrency(currency)
    }

    const handleCheckbox = (e) => {
        setNoExpiry(e.target.checked)
    }

    const RegisterSchema = Yup.object().shape({
        // paymentFor: Yup.string().required('Name is required'),
        amount: Yup.number().min(0.02).required("Amount is required"),
    });

    const defaultValues = {
        paymentFor: '',
        amount: 0.02,
        currency: 'solana',
        expiry: moment().add(1, "days").format("YYYY-MM-DDTHH:mm"),
        noteTitle: "",
        noteDescription: "",
    };

    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = (data) => {
        data.amount = amount;
        data.network = currency;
        data.noExpiry = noExpiry;

        if (amount < 0.02) {
            return toast.error('Invalid amount');
        }

        if (!data.noExpiry) {
            if (moment(data.expiry).unix() <= moment().unix()) {
                toast.error("The expiry date must be in the future");
                return;
            }
        }

        if (!data.noteTitle && data.noteDescription) {
            toast.error("Please enter title for the note");
            return;
        }
        if (data.noteTitle) {
            const notes = {};
            notes.title = data.noteTitle;

            if (data.noteDescription) {
                notes.description = data.noteDescription;
            }
            data.notes = [notes];
        }
        data.expiry = !data.noExpiry ? moment(data.expiry).unix().toString() : "0";

        handleClose();
        dispatch(createNewPlink(data));
    };

    return (
        <>
            <Grid container justifyContent="center" style={{ outline: "none" }}>
                <Grid item lg={4} md={6} xs={11}>
                    <Box sx={style}>

                        <Typography variant="h4" align="center" gutterBottom>
                            Create Payment Link
                        </Typography>

                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            <Stack spacing={2}>
                                <RHFTextField name="paymentFor" label="Payment for" placeholder="Purpose of the payment" size='small' />
                                <RHFTextField
                                    name="amount"
                                    type="number"
                                    label="Amount"
                                    size='small'
                                    value={amount}
                                    // inputProps={{ min: 0.02 }}
                                    onChange={handleAmountChange}
                                />
                                <FormControl fullWidth>
                                    <Select
                                        name='currency'
                                        value={currency}
                                        size='small'
                                        onChange={handleCurrencyChange}
                                    >
                                        <MenuItem value={'solana'}>Solana</MenuItem>
                                        <MenuItem value={'sol_usdc'}>USDC (solana chain)</MenuItem>
                                        <MenuItem value={'sol_usdt'}>USDT (solana chain)</MenuItem>
                                        {/* <MenuItem value={'ethereum'}>Ethereum</MenuItem>
                                        <MenuItem value={'avalanche'}>Avalanche</MenuItem>
                                        <MenuItem value={'polygon'}>Polygon Matic</MenuItem>
                                        <MenuItem value={'eth_usdc'}>USDC</MenuItem> */}
                                    </Select>
                                </FormControl>

                                <Stack direction="row" spacing={0}>
                                    <FormControlLabel
                                        style={{ width: "40%" }}
                                        control={
                                            <Checkbox name='noExpiry' size='small' checked={noExpiry} onChange={handleCheckbox} />
                                        }
                                        label="No Expiry"
                                    />
                                    <RHFTextField
                                        name="expiry"
                                        type='datetime-local'
                                        size='small'
                                        inputProps={{ min: moment().add(1, "days").format("YYYY-MM-DDTHH:mm") }}
                                        style={{ width: "60%" }}
                                        disabled={noExpiry}
                                    />
                                </Stack>

                                <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
                                    Notes
                                </Typography>
                                <RHFTextField name="noteTitle" label="Title" placeholder='Title of the note' size='small' />
                                <RHFTextField name="noteDescription" label="Description" placeholder="Description of the note" size='large' />

                                <LoadingButton fullWidth type="submit" variant="contained" loading={isSubmitting}>
                                    Create Link
                                </LoadingButton>
                                <Button onClick={handleClose}>
                                    Cancel
                                </Button>
                            </Stack>
                        </FormProvider>

                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default NewLinkModalContent