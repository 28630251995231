import React from 'react';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { FormProvider, RHFTextField } from '../../components/hook-form';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { updateBlockchainAccounts } from 'store/actions';
import { sentenceCase } from 'change-case';

const style = {
    width: "auto",
    bgcolor: "background.paper",
    border: "none",
    outline: "none",
    borderRadius: "10px",
    boxShadow: 24,
    p: 3,
};

function UpdateWalletModalContent({ address, network, dispatch, handleClose }) {

    const RegisterSchema = Yup.object().shape({
        address: Yup.string().required('Wallet address is required'),
    });

    const defaultValues = {
        address: address,
    };

    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = (data) => {
        if (!data.address) {
            return toast.warning(`Please enter ${network} address`)
        }

        if (network === 'USDC') {
            network = 'eth_usdc'
        }

        data.network = network
        handleClose();
        dispatch(updateBlockchainAccounts({ blockchainAccounts: [data] }))
    };

    return (
        <>
            <Grid container justifyContent="center" style={{ outline: "none" }}>
                <Grid item lg={4} md={6} sm={8} xs={11}>
                    <Box sx={style}>
                        <Typography variant="h4" align="center" gutterBottom>
                            Update {sentenceCase(network)} Wallet Address
                        </Typography>
                        <br />

                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            <Stack spacing={2}>
                                <RHFTextField name="address" label="Wallet Address" placeholder={`Enter ${sentenceCase(network)} Wallet Address`} />

                                <LoadingButton fullWidth type="submit" variant="contained" loading={isSubmitting}>
                                    Submit
                                </LoadingButton>
                                <Button onClick={handleClose}>
                                    Cancel
                                </Button>
                            </Stack>
                        </FormProvider>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default UpdateWalletModalContent