export const FETCH_HOMEPAGE_REPORTS = `query ($startDate: Int, $endDate: Int) {
  homepage_reports(start_date: $startDate, end_date: $endDate){
    total_txns
    total_solana
    total_ethereum
    total_avalanche
    total_polygon
    settled_amounts {
      network
      amount
    }
    unsettled_amounts {
      network
      amount
    }
  }
}`;
