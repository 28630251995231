import React, { useState } from "react";
import { Icon } from "@iconify/react";
import Iconify from "components/Iconify";
import Page from "components/Page";
import {
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { apiCallAuthorized } from "store/actions";
import { CREATE_NEW_PAYMENT_PAGE } from "graphql";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getBlockchainCurrency } from "utils";

const errMsgStyle = {
  marginTop: 0,
  marginLeft: "10px",
  display: "block",
  color: "red",
  fontSize: 13,
}

export default function CustomPaymentPage() {
  const navigate = useNavigate();
  const [amountType, setAmountType] = useState('cust_decide');
  // const [amount, setAmount] = useState(0.02);
  const [network, setNetwork] = useState('solana');
  const [newField, setNewField] = useState({ arr: [] });

  const handleAmountTypeChange = (event) => {
    setAmountType(event.target.value);
  };

  const handleNewFieldBtn = () => {
    var joined = newField.arr.concat({ name: '', value: '' });
    setNewField({ arr: joined })
  }

  const handleNewFieldValueChange = (i, newValue, keyName) => {
    var valueAdd = newField.arr;
    valueAdd[i][keyName] = newValue.target.value
    setNewField({ arr: valueAdd })
  }

  const removeField = (index) => {
    var arr = newField.arr;
    var filtered = arr.filter((e, i) => i !== index);
    setNewField({ arr: filtered })
  }

  const finalSubmit = async (value) => {
    if (amountType === 'cust_decide'){
      value.amount = 0;
    }

    if (newField?.arr?.length) {
      value.fields = newField?.arr;
    }else {
      value.fields = [{name: "description", value: value.description }];
    }
    value.amountType = amountType;
    value.network = network;
    let url = await apiCallAuthorized(CREATE_NEW_PAYMENT_PAGE, value);
    if(url) {
      toast.success("Payment Page Created");
      navigate("/dashboard/payment-pages");
    }
  }

  return (
    <>
      <Page title="Payment Page: Details">
        <Container>
          <Formik
            initialValues={{
              title: "",
              description: "",
              contactUsEmail: "",
              supportPhone: "",
              amount: 0.02
            }}
            validationSchema={Yup.object().shape({
              title: Yup.string().max(255).required("Title is required"),
              contactUsEmail: Yup.string().email("Must be a valid email").max(255).required("Email field is required"),
              amount: Yup.number().min(0.02).required("Amount is required"),
            })}
            onSubmit={finalSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Stack direction="row" alignItems="center" justifyContent="flex-end" mb={5}>
                  {/* <Typography variant="h4" gutterBottom>
            Create your own payment page
          </Typography> */}
                  <Button variant="contained" type="submit" disabled={values.title === "" || values.contactUsEmail === ""}>
                    Create & Publish Page
                  </Button>
                </Stack>
                <Grid container spacing={2}>
                  <Grid item lg={6} xs={12}>
                    <Card sx={{ p: 3 }}>
                      <Stack spacing={2}>
                        <TextField
                          fullWidth
                          name="title"
                          label="Enter page title"
                          InputLabelProps={{
                            style: { fontSize: "20px", fontWeight: "bold", backgroundColor: '#fff', paddingRight: '5px' },
                          }}
                          defaultValue={values.title}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(touched.title && errors.title)}

                        />
                        {Boolean(touched.title && errors.title) ? (
                          <div style={errMsgStyle}> {errors.title} </div>
                        ) : (
                          ""
                        )}

                        <TextField
                          fullWidth
                          name="description"
                          label="Enter page description"
                          size="small"
                          defaultValue={values.description}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <Stack spacing={1}>
                          <Typography variant="subtitle1" sx={{ ml: 1 }}>
                            Contact us
                          </Typography>
                          <OutlinedInput
                            size="small"
                            name="contactUsEmail"
                            type="email"
                            autoComplete="on"
                            placeholder="Enter support email"
                            startAdornment={
                              <InputAdornment position="start">
                                <Icon icon="clarity:email-solid" />
                              </InputAdornment>
                            }
                            defaultValue={values.contactUsEmail}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={Boolean(touched.contactUsEmail && errors.contactUsEmail)}
                          />
                          {Boolean(touched.contactUsEmail && errors.contactUsEmail) ? (
                            <div style={errMsgStyle}> {errors.contactUsEmail} </div>
                          ) : (
                            ""
                          )}
                          <OutlinedInput
                            size="small"
                            name="supportPhone"
                            type="text"
                            autoComplete="on"
                            placeholder="Enter support phone"
                            startAdornment={
                              <InputAdornment position="start">
                                <Icon icon="ant-design:phone-filled" />
                              </InputAdornment>
                            }
                            defaultValue={values.supportPhone}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={Boolean(touched.supportPhone && errors.supportPhone)}
                          />
                          {Boolean(touched.supportPhone && errors.supportPhone) ? (
                            <div style={errMsgStyle}> {errors.supportPhone} </div>
                          ) : (
                            ""
                          )}
                        </Stack>

                        <br />
                        {newField.arr.map((e, i) => (
                          <Stack direction='row' spacing={1} key={i}>
                            <OutlinedInput
                              fullWidth
                              size="small"
                              placeholder="Name of the field"
                              onChange={(newValue) => handleNewFieldValueChange(i, newValue, 'name')}
                              startAdornment={
                                <InputAdornment position="start">
                                  <Icon icon="material-symbols:drive-file-rename-outline" />
                                </InputAdornment>
                              }
                            />

                            <OutlinedInput
                              fullWidth
                              size="small"
                              placeholder="Value of the field"
                              defaultValue={newField.arr[i].value}
                              onChange={(newValue) => handleNewFieldValueChange(i, newValue, 'value')}
                              startAdornment={
                                <InputAdornment position="start">
                                  <Icon icon="material-symbols:drive-file-rename-outline" />
                                </InputAdornment>
                              }
                            />
                            <Button title='Remove' size='small' onClick={() => removeField(i)}>
                              <Icon icon="akar-icons:minus" />
                            </Button>
                          </Stack>
                        ))}
                        <Button startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleNewFieldBtn}>
                          Add new field
                        </Button>
                      </Stack>
                    </Card>
                  </Grid>

                  <Grid item lg={6} xs={12}>
                    <Card sx={{ p: 3 }}>
                      <Stack spacing={2}>
                        <Typography variant="h6">
                          Payment Details
                        </Typography>
                        <Stack direction='row' spacing={1} alignItems='center'>
                          <Typography variant="body1" sx={{ width: '50%' }}>
                            Amount Type
                          </Typography>
                          <FormControl fullWidth variant="outlined" size='small' sx={{ minWidth: 120 }}>
                            <Select
                              value={amountType}
                              onChange={handleAmountTypeChange}
                            >
                              <MenuItem value={'cust_decide'}>Customer Decide</MenuItem>
                              <MenuItem value={'fixed'}>Fixed Amount</MenuItem>
                            </Select>
                          </FormControl>
                        </Stack>
                        {amountType === 'fixed' &&
                        <>
                        <Stack direction='row' spacing={1} alignItems='center'>
                          <Typography variant="body1" sx={{ width: '50%' }}>
                            Amount
                          </Typography>
                            <Stack sx={{width: "100%"}}>
                              <OutlinedInput
                                fullWidth
                                size="small"
                                name="amount"
                                type="number"
                                placeholder="Enter the fixed amount"
                                // inputProps={{ min: 0.02 }}
                                value={values.amount}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={Boolean(touched.amount && errors.amount)}
                              />
                              {Boolean(touched.amount && errors.amount) ? (
                                <div style={errMsgStyle}> {errors.amount} </div>
                              ) : (
                                ""
                              )}
                            </Stack>
                        </Stack>
                        <Stack direction='row' spacing={1} alignItems='center'>
                          <Typography variant="body1" sx={{ width: '50%' }}>
                            Network
                          </Typography>
                          <FormControl fullWidth variant="outlined" size='small' sx={{ minWidth: 120 }}>
                            <Select
                              value={network}
                              onChange={(e) => setNetwork(e.target.value)}
                            >
                              <MenuItem value={'solana'}>Solana</MenuItem>
                              <MenuItem value={'sol_usdc'}>USDC (solana chain)</MenuItem>
                              <MenuItem value={'sol_usdt'}>USDT (solana chain)</MenuItem>
                              {/* <MenuItem value={'ethereum'}>Ethereum</MenuItem>
                              <MenuItem value={'avalanche'}>Avalanche</MenuItem>
                              <MenuItem value={'polygon'}>Polygon</MenuItem>
                              <MenuItem value={'eth_usdc'}>USDC</MenuItem> */}
                            </Select>
                          </FormControl>
                        </Stack>
                        </>}

                        <Stack direction='row' spacing={1} alignItems='center'>
                          <Typography variant="body1" sx={{ width: '50%' }}>
                            Email
                          </Typography>
                          <OutlinedInput
                            fullWidth
                            disabled
                            size="small"
                            placeholder="To be filled by customer"
                            startAdornment={
                              <InputAdornment position="start">
                                <Icon icon="clarity:email-solid" />
                              </InputAdornment>
                            }
                          />
                        </Stack>
                        <Stack direction='row' spacing={1} alignItems='center'>
                          <Typography variant="body1" sx={{ width: '50%' }}>
                            Phone
                          </Typography>
                          <OutlinedInput
                            fullWidth
                            disabled
                            size="small"
                            placeholder="To be filled by customer"
                            startAdornment={
                              <InputAdornment position="start">
                                <Icon icon="ant-design:phone-filled" />
                              </InputAdornment>
                            }
                          />
                        </Stack>
                        <Button sx={{cursor: 'not-allowed'}} size="large" variant="contained">
                          {/* Pay {amountType === 'fixed' ? <>{values.amount} {getBlockchainCurrency(network)?.toUpperCase()} </>: ''} */}
                          Submit
                        </Button>
                      </Stack>
                    </Card>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Container>
      </Page>
    </>
  );
}
